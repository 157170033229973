import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";
import createPersistedState from "vuex-persistedstate";
import modules from "./modules";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules,
  plugins: [
    // createPersistedState()
  ],
});

export default store;

export const limparDadosBasicos = () => {
  store.commit("Matriculas/setMatriculas", []);
  store.commit("Turmas/setTurmas", []);
};
export const limparTodosOsDados = () => {
  store.commit("Alunos/setAlunos", []);
  store.commit("Aulas/setAulas", []);
  store.commit("Cursos/setCursos", []);
  store.commit("Disciplinas/setDisciplinas", []);
  store.commit("Franquias/setFranquias", []);
  store.commit("GestoesDeAulas/setGestoesDeAulas", []);
  store.commit("Matriculas/setMatriculas", []);
  store.commit("Roles/setRoles", []);
  store.commit("Turmas/setTurmas", []);
};
